<template>
  <div>
    <!-- <div class="vx-row mb-12">
            <vs-button v-on:click="create()" icon="done" color="primary">Create Payment</vs-button>
        </div> -->
    <div class="vx-row mb-12">
      <div
        v-bind:class="[
          detail ? detailShow + ' vertical-divider' : '',
          detailHide,
        ]"
      >
        <data-table
          :responseData="responseData"
          :propsParams="params"
          :header="header"
          @reloadDataFromChild="reloadData"
        >
          <template slot="thead">
            <!-- <th width="15%">
                  <vs-checkbox color="success" v-on:click="addAllValidate()" v-model="validateAll">Release All</vs-checkbox>
                </th> -->
          </template>
          <template slot="tbody">
            <vs-tr :key="indextr" v-for="(tr, indextr) in responseData.records">
              <vs-td class="whitespace-no-wrap">
                <div class="mt-4 flex mr-1">
                  <vx-tooltip text="Print" class="mr-4">
                    <vs-button
                      color="primary"
                      type="line"
                      icon-pack="feather"
                      @click="printPDF(tr)"
                      icon="icon-printer"
                    />
                  </vx-tooltip>
                  <vx-tooltip text="Show" class="mr-4">
                    <vs-button
                      color="green"
                      type="line"
                      icon-pack="feather"
                      @click="edit(tr)"
                      icon="icon-eye"
                    />
                  </vx-tooltip>

                  <!-- <vx-tooltip text="Cancel" class="mr-4">
                    <vs-button
                      color="red"
                      type="line"
                      icon-pack="feather"
                      @click="promptCancel(tr)"
                      icon="icon-x-square"
                    />
                  </vx-tooltip> -->
                </div>
              </vs-td>
              <vs-td>
                {{ tr.shipment_number }}
              </vs-td>
              <vs-td>
                {{ dateFormat(tr.delivery_plan_date) }}
              </vs-td>
              <vs-td>
                {{ tr.count_do_pickup }}
              </vs-td>
              <vs-td>
                {{ tr.driver_name }}
              </vs-td>
              <vs-td>
                {{ tr.vehicle_name }}
              </vs-td>
              <vs-td>
                {{ tr.shipment_cost_code }}
              </vs-td>
              <vs-td>
                {{
                  tr.type === 1 ? "Internal" : tr.type === 2 ? "Oncall" : null
                }}
              </vs-td>
              <vs-td>
                  {{ tr.advance_cash_value==null?"":formatPrice(tr.advance_cash_value) }}
              </vs-td>
            </vs-tr>
          </template>
        </data-table>
      </div>
      <vs-popup
        fullscreen
        title="fullscreen"
        :active.sync="showPdf"
        :button-close-hidden="false"
      >
        <div class="flex flex-col gap-6 h-full">
          <iframe :src="pdfUrlPopup" class="w-full" style="min-height: 80vh" />
          <div class="flex gap-3 w-full justify-end">
            <vs-button color="danger" @click="handleClosePopUp">Cancel</vs-button>
            <vs-Button @click="handlePrintPDFPreview">Print</vs-Button>
          </div>
        </div>
      </vs-popup>
      <vs-prompt
        title="Confirmation"
        color="primary"
        @accept="confirmAdjustment"
        @cancel="closeDetail"
        @close="closeDetail"
        :buttons-hidden="false"
        :active.sync="confirmPrompt"
      >
        <div class="con-exemple-prompt">
          AR Clearing
          <br />
          Are you sure to confirm
          <b>{{ this.selectedData.Code }}</b> AR-Clearing ?
          <br />
          <br />
          <!-- <vs-textarea v-model="notes"/> -->
        </div>
      </vs-prompt>
      <vs-prompt
        title="Confirmation"
        color="primary"
        @accept="cancelAdjustment"
        @cancel="closeDetail"
        @close="closeDetail"
        :buttons-hidden="false"
        :active.sync="cancelPrompt"
      >
        <div class="con-exemple-prompt">
          AR Clearing
          <br />
          Are you sure to cancel <b>{{ this.selectedData.Code }}</b> AR-Clearing
          ?
          <br />
          <br />
          <!-- <vs-textarea v-model="notes"/> -->
        </div>
      </vs-prompt>
      <transition name="detail-fade">
        <div
          v-if="detail"
          v-bind:class="[
            detail ? ' nonfixed vx-col md:w-2/3 w-full mb-base' : '',
            detailHide,
          ]"
        >
          <div>
            <vs-col
              style="padding-bottom: 8px"
              vs-offset="8"
              vs-type="flex"
              vs-justify="rigth"
              vs-align="rigth"
              vs-w="4"
            >
              <vs-button
                size="small"
                v-on:click="closeDetail"
                color="grey"
                icon-pack="feather"
                icon="icon-x-square"
                >Close</vs-button
              >
            </vs-col>
            <template v-if="selectedData.ID == 0">
              <create @closeDetail="closeDetail" />
            </template>
            <template v-else>
              <edit :selected="selectedData" @closeDetail="closeDetail" />
            </template>
          </div>
        </div>
      </transition>
    </div>
  </div>
</template>

<script>
import create from "../form-create.vue";
import edit from "./form-edit.vue";
import moment from "moment";
import { jsPDF } from "jspdf";
import autoTable from "jspdf-autotable";
export default {
  props: {
    selected: Object,
    option: Object,
    shipmentDate: Object,
    filterDriver: Object,
    filterVehicle: Object,
    filterType: Object,
  },
  components: {
    create,
    edit,
  },
  data() {
    return {
      typePayment: ["", "Giro", "Cheque", "Transfer"],
      confirmPrompt: false,
      cancelPrompt: false,
      status: 3,
      params: {
        search: "",
        length: 10,
        page: 1,
        order: "desc",
        sort: "sc.id",
      },
      header: [
        {
          text: "Action",
          sortable: false,
        },
        {
          text: "Shipment Doc",
          value: "shipment_number",
          // width: '5%'
        },
        {
          text: "Shipment Date",
          value: "delivery_plan_date",
        },
        {
          text: "Number of DO",
          value: "count_do_pickup",
        },
        {
          text: "Driver",
          value: "driver_name",
        },
        {
          text: "Vehicle",
          value: "vehicle_name",
        },
        {
          text: "Advance Cash Doc",
          value: "shipment_number",
        },
        {
          text: "Shipment Cost Type",
          value: "type",
        },
        {
            text: "Value",
            // value: "type",
        },
      ],
      responseData: {},
      detailShow: "vx-col md:w-1/3 w-full mb-base",
      detailHide: "core vx-col md:w-1/1 w-full mb-base",
      detail: false,
      selectedData: {},
      driver_ids: [],
      vehicle_ids: [],
      type_name: [],
      pdfUrlPopup: "",
      showPdf: false,
      pdfProxyClass: null,
    };
  },
  computed: {},
  watch: {
    shipmentDate() {
        this.reloadData(this.params);
    },
    filterDriver() {
        this.reloadData(this.params);
    },
    filterVehicle() {
        this.reloadData(this.params);
    },
    filterType() {
        this.reloadData(this.params);
    },
  },
  mounted() {
    // this.reloadData(this.params);
    // this.getData();
    // this.page(1);
    // this.dataId = this.data[0].id
  },
  methods: {
    create() {
      this.selectedData = {
        ID: 0,
      };
      this.detail = true;
    },
    edit(selected) {
      this.selectedData = selected;
      console.log(selected);
      this.detail = true;
    },
    promptConfirm(data) {
      this.confirmPrompt = true;
      this.status = 2;
      this.cancelPrompt = false;
      this.selectedData = data;
      console.log("confirm");
    },
    promptCancel(data) {
      this.confirmPrompt = false;
      this.status = 4;
      this.cancelPrompt = true;
      this.selectedData = data;
      console.log("confirm");
    },
    dateFormat(value) {
      return moment(String(value)).format("DD/MM/YYYY");
    },
    closeDetail(params = false) {
      console.log(params);
      window.scrollTo(0, 0);
      this.detail = false;
      this.confirmPrompt = false;
      this.status = 2;
      this.cancelPrompt = false;
      if (params == true || this.selectedData.ID != 0) {
        this.reloadData(this.params);
      }
      this.selectedData = {
        ID: 0,
      };
    },
    handleClosePopUp() {
      this.showPdf = false;
      this.pdfUrlPopup = "";
      this.pdfProxyClass = null;
    },
    handlePrintPDFPreview() {
      try {
        // this.$vs.loading();

        console.log(this.pdfProxyClass);
        const name = "shipment-cost-settled.pdf";
        this.pdfProxyClass.save(name);

        this.showPdf = false;
        this.pdfUrlPopup = "";
        this.pdfProxyClass = null;
        // this.checked = [];
        // this.checkedCode = [];
        // this.usingCloseButton = true;
        // this.proposals = [];
        // this.$vs.loading.close();
      } catch (e) {
        console.log(e);
        this.$vs.loading.close();
        this.$vs.notify({
            color: "danger",
            title: "Print",
            text: "Failed to print",
            position: "top-right",
            iconPack: "feather",
            icon: "icon-x-circle",
        });
        // this.checked = [];
        // this.checkedCode = [];
      }
    },
    confirmAdjustment() {
      let params = {
        id: this.selectedData.ID,
        status: this.status,
      };
      this.$vs.loading();
      this.$http
        .post("/api/v1/finance/adjustment/confirm", params)
        .then((result) => {
          this.$vs.loading.close();
          if (result.code == 200) {
            this.closeDetail(true);
            this.$vs.notify({
              title: "Success",
              text: "",
              color: "success",
              position: "top-right",
              iconPack: "feather",
              icon: "icon-check",
            });
          } else {
            this.closeDetail();
            this.$vs.notify({
              title: "Error",
              text: result.message,
              color: "danger",
              position: "top-right",
              iconPack: "feather",
              icon: "icon-check",
            });
          }
        })
        .catch((e) => {
          console.log(e);
        });
    },
    cancelAdjustment() {
      let params = {
        id: this.selectedData.ID,
        status: this.status,
      };
      this.$vs.loading();
      this.$http
        .post("/api/v1/finance/adjustment/cancel", params)
        .then((result) => {
          this.$vs.loading.close();
          if (result.code == 200) {
            this.closeDetail();
            this.$vs.notify({
              title: "Success",
              text: "",
              color: "success",
              position: "top-right",
              iconPack: "feather",
              icon: "icon-check",
            });
          } else {
            this.closeDetail();
            this.$vs.notify({
              title: "Error",
              text: result.message,
              color: "danger",
              position: "top-right",
              iconPack: "feather",
              icon: "icon-check",
            });
          }
        })
        .catch((e) => {
          console.log(e);
        });
    },
    dataTable(params) {
      return new Promise((resolve, reject) => {
        this.$http
          .get("/api/v1/uang-jalan/shipment-cost/datatable", {
            params: {
              status: 4,
              type: 4,
              view_only: true,
              search: params.search,
              length: params.length,
              page: params.page,
              order: params.order,
              sort: params.sort,
              start_shipment_date:this.shipmentDate.startDate ? moment(this.shipmentDate.startDate).format("YYYY-MM-DD") : null,
              end_shipment_date:this.shipmentDate.endDate ? moment(this.shipmentDate.endDate).format("YYYY-MM-DD") : null,
              driver_ids:this.driver_ids,
              vehicle_ids:this.vehicle_ids,
              type_names:this.type_names,
            },
          })
          .then((r) => {
            resolve(r);
          })
          .catch((e) => {
            reject(e);
          });
      });
    },
    reloadData(params) {
      this.driver_ids = this.filterDriver.map(x => x.id),
      this.vehicle_ids = this.filterVehicle.map(x => x.id),
      this.type_names =  this.filterType.map(x => x.name),
      this.params = params;
      console.log(params);
      this.$vs.loading();
      const dataTable = this.dataTable(params);
      dataTable.then((r) => {
        console.log(r);
        if (r.code == 500) {
          this.$vs.loading.close();
        } else if (r.code == 200) {
          this.$vs.loading.close();
          this.data = r.data.records;
          this.responseData = r.data;
          // this.responseData.length = r.data.otherPayment.length;
          // this.checkedAll = false;
        } else {
          this.$vs.loading.close();
        }
      });
    },
    async printPDF(tr) {
      try {
        if (tr == null) {
          this.$vs.notify({
            color: "danger",
            title: "Print",
            text: "Please select at least one proposal to be printed",
            position: "top-right",
            iconPack: "feather",
            icon: "icon-x-circle",
          });
          return;
        }
        // this.$vs.loading();
        const padding = 0.5;
        const height = 21;
        const width = 29.7;
        // const pageWidth = width - padding * 2;
        // const pageHeight = height - padding * 2;

        const data = JSON.parse(JSON.stringify(tr));
        console.log(data, "PRINT DATA");
        var accountingData = JSON.parse(data.accounting_data)
        // const proposals = JSON.parse(JSON.stringify(this.checkedCode));
        const doc = new jsPDF({
          orientation: "potrait",
          unit: "mm",
        });

        // var headerText = "REALISASI UANG MUKA";
        // var headerFontSize = 16;
        // var headerTextWidth = doc.getTextWidth(headerText);
        var pageWidth = doc.internal.pageSize.getWidth();
        var pageHeight = doc.internal.pageSize.getHeight();
        // var headerY = 20;
        let cursorY = 10;
        // doc.setFontSize(headerFontSize);
        // doc.setFont("times", "bold");
        // doc.text(
        //   headerText,
        //   this.setPos(pageWidth, (pageWidth - headerTextWidth) / 2, padding),
        //   this.setPos(pageHeight, cursorY, padding)
        // );

        // cursorY += 10;
        doc.setFontSize(14);

        let shipmentCostLine = [];
        const settlementDate = accountingData.trans_date === "0001-01-01T00:00:00Z" ? "" : accountingData.trans_date;
        this.$http
          .get("api/v1/uang-jalan/transport-plan/line", {
              params: {
                id: data.shipment_cost_id,
              },
          })
          .then((resp) => {
              if (resp.code == 200) {
                  shipmentCostLine = resp.data.shipment_cost_line;
                  // console.log("this.option.transport_plan", this.option.transport_plan);

                  //format header
                  const dummyHeaderLeft = [
                    {
                      label: "Cabang",
                      separator: ":",
                      value: data.cabang,
                    },
                    {
                      label: "Driver",
                      separator: ":",
                      value: data.driver_name,
                    },
                    {
                      label: "Vehicle No.",
                      separator: ":",
                      value: data.vehicle_number,
                    },
                  ];

                  const dummyHeaderRight = [
                    {
                      label: "No. Uang Muka",
                      separator: ":",
                      value: data.shipment_number,
                    },
                    {
                      label: "Tgl Realisasi",
                      separator: ":",
                      value: moment(settlementDate).format("DD MMMM YYYY"),
                    },
                    {
                      label: "Keterangan",
                      separator: ":",
                      value: "Realisasi Uang Jalan "+ data.settlement_code,
                    },
                    {
                      label: "Uang Muka",
                      separator: ":",
                      value: "Rp. "+ this.formatPrice(data.advance_cash_value),
                    },
                  ];

                  cursorY = 55;

                  let dataPrint = [];
                  let total = 0;
                  shipmentCostLine.forEach((val, idx) => {
                    dataPrint.push({
                      no: idx+1,
                      code: val.expense_type_code,
                      fund_name: val.expense_type_name,
                      do_number: val.delivery_order_code,
                      customer_name: val.customer_name,
                      realization_total: this.formatPrice(val.value),
                    })

                    total += val.value;
                  });

                  dataPrint.push({
                    no: "Total",
                    code: "",
                    fund_name: "",
                    do_number: "",
                    customer_name: "",
                    realization_total: this.formatPrice(total),
                  })

                  const finalY = this.generateData(
                    doc,
                    dataPrint,
                    this.setPos(pageHeight, cursorY, padding),
                    (pageWidth - 500) * 2,
                    pageHeight
                  );

                  const pageCount = doc.internal.getNumberOfPages();
                  for (let i = 1; i <= pageCount; i++) {
                    doc.setPage(i);
                    var headerText = "REALISASI UANG MUKA";
                    var headerFontSize = 16;
                    var headerTextWidth = doc.getTextWidth(headerText);
                    // var headerY = 20;
                    cursorY = 10;
                    doc.setFontSize(headerFontSize);
                    doc.setFont("times", "bold");
                    doc.text(
                      headerText,
                      this.setPos(pageWidth, (pageWidth - headerTextWidth) / 2, padding),
                      this.setPos(pageHeight, cursorY, padding)
                    );

                    cursorY = 20
                    const res = this.generateHeaderInfo(
                      doc,
                      dummyHeaderLeft,
                      this.setPos(pageHeight, cursorY, padding),
                      pageWidth,
                      pageHeight
                    );
  
                    // console.log(res.finalX, "FINAL X");
                    this.generateHeaderInfo(
                      doc,
                      dummyHeaderRight,
                      this.setPos(pageHeight, cursorY, padding),
                      pageWidth,
                      pageHeight,
                      this.setPos(pageWidth, res.finalX - 1, padding)
                    );
                  }
                  
                  // cursorY = finalY;

                  // if (cursorY + 5 >= pageHeight) {
                  //   doc.addPage("", "potrait");
                  //   cursorY = 1;
                  // } else {
                  //   cursorY += 2;
                  // }

                  // this.generateFooter(
                  //   doc,
                  //   this.setPos(pageHeight, cursorY, padding),
                  //   (pageWidth - 500) * 2,
                  //   pageHeight
                  // );
                  this.generateFooter(
                    doc,
                    pageHeight-50,
                    (pageWidth - 500) * 2,
                    pageHeight
                  );

                  // doc.save("shipment-cost-settlement-"+data.settlement_code+".pdf");
                  // this.pdfProxyClass = doc;
                  // this.pdfUrlPopup = doc.output("dataurlstring");
                  // this.pdfUrlPopup += "#toolbar=0";
                  // this.showPdf = true;

                  const pdfBlob = doc.output('blob');
                  const blobUrl = URL.createObjectURL(pdfBlob);
                  window.open(blobUrl, '_blank');
              } else {
                  this.$vs.notify({
                      title: "Error",
                      text: "Failed to get Transport Plan option",
                      color: "danger",
                      position: "top-right",
                      iconPack: "feather",
                      icon: "icon-check",
                  });
              }
              this.$vs.loading.close();
          });

        
        // this.$vs.loading.close();
      } catch (e) {
        console.log(e);
        this.$vs.loading.close();
      }
    },
    setPos(pageWidth, pos, offset) {
      if (pos >= pageWidth) {
        return pageWidth;
      } else {
        if (pos <= 0) {
          return offset;
        } else {
          return (pos += offset);
        }
      }
    },
    generateHeaderInfo(doc, data, startY, pageWidth, pageHeight, startX = 0) {
      const header = [
        { header: "", dataKey: "label" },
        { header: "", dataKey: "separator" },
        { header: "", dataKey: "value" },
      ];
      const fWidth = this.setPos(pageWidth, (pageWidth - 13) / 2, 1);
      const firtTwoWidth = 3.7;
      autoTable(doc, {
        startY: startY,
        columns: header,
        showHead: "never",
        body: data,
        styles: {
          lineWidth: 0,
          lineColor: [0, 0, 0],
          fontSize: 12,
          cellPadding: 0.2,
          font: "times",
          valign: "middle",
          halign: "left",
        },
        columnStyles: {
          label: { cellWidth: 30 },
          separator: { cellWidth: 7 },
          value: { cellWidth: 60 },
        },
        theme: "plain",
        margin: {
          left:
            startX === 0 ? undefined : this.setPos(pageWidth, startX + 1.5, 1),
        },
        didParseCell(data) {
          data.cell.styles.fontSize = 11;

          const columnIndex = data.column.index;
          if (columnIndex === 1) {
            // data.cell.styles.valign = "center";
            data.cell.styles.halign = "left";
          } else if (columnIndex === 0) {
            data.cell.styles.fontStyle = "bold";
          }
        },
      });
      return {
        finalX: firtTwoWidth + fWidth,
        finalY: doc.lastAutoTable.finalY,
      };
    },
    generateFooter(doc, startY, pageWidth, pageHeight) {
      const header = [
        { header: "Dibuat oleh:", dataKey: "a" },
        { header: "Diketahui", dataKey: "b" },
        { header: "Diterima:", dataKey: "c" },
      ];
      const width = this.setPos(pageWidth, pageWidth, 1) / 4;
      autoTable(doc, {
        startY: startY,
        columns: header,
        body: [{ a: "", b: "", c: "" },
        { a: "Admin Traffic", b: "BLC/Spv. Log", c: "Kasir"}],
        styles: {
          lineWidth: 0.01,
          lineColor: [0, 0, 0],
          fontSize: 12,
          minCellHeight: 0.1,
          cellPadding: 0.2,
          font: "times",
        },
        headStyles: {
          fillColor: [219, 219, 219],
          lineWidth: 0.01,
          lineColor: [0, 0, 0],
          fontStyle: "bold",
          valign: "middle",
          halign: "center",
          cellPadding: 0.1,
          minCellHeight: 0.1,
          font: "times",
        },
        columnStyles: {
          a: { cellWidth: width },
          b: { cellWidth: width },
          c: { cellWidth: width },
        },
        theme: "plain",
        didParseCell(data) {
          data.cell.styles.fontSize = 11;

          if (data.row.index === 1) {
            console.log(data, "pageWidth last");
            const customRowHeight = 5; // Set the desired custom height for the last row
            data.row.height = customRowHeight;
            data.cell.height = customRowHeight;
            data.cell.contentHeight = customRowHeight;
            data.cell.styles.valign = "middle";
            data.cell.styles.halign = "center";
          } else if (data.row.index === 0 && data.section == "body")  {
            console.log(data, "pageWidth not");
            const customRowHeight = 25; // Set the desired custom height for the last row
            data.row.height = customRowHeight;
            data.cell.height = customRowHeight;
            data.cell.contentHeight = customRowHeight;
            data.cell.styles.minCellHeight = customRowHeight;
          }
        },
      });
    },

    generateData(doc, data, startY, pageWidth, pageHeight) {
      const header = [
        { header: "No", dataKey: "no" },
        { header: "Kode", dataKey: "code" },
        { header: "Nama Biaya", dataKey: "fund_name" },
        { header: "DO No", dataKey: "do_number" },
        { header: "Nama Pelanggan", dataKey: "customer_name" },
        { header: "Jumlah Realisasi", dataKey: "realization_total" },
      ];
      const width = this.setPos(pageWidth, pageWidth, 1) / 6;
      const body = data;
      let cursorY = 50 + 0.2;
      let colHeight = 0;
      let isShowHeader = false;

      for (let i = 0; i < body.length; i++) {
        const innerBody = [body[i]];
        console.log(cursorY + colHeight, "cursorY + colHeight");
        console.log(cursorY, "cursorY");
        console.log(colHeight, "colHeight");
        console.log(pageHeight, "pageHeight");
        console.log(cursorY + colHeight >= pageHeight, "pageHeight");
        console.log(innerBody, "pageHeight");

        if (cursorY + colHeight >= pageHeight-15) {
          console.log("disini jalan");
          doc.addPage();
          doc.setPage(doc.internal.getNumberOfPages());
          cursorY = 50 + 0.2;
          isShowHeader = true;
        }

        autoTable(doc, {
          startY: cursorY,
          columns: header,
          body: innerBody,
          styles: {
            lineWidth: 0,
            lineColor: [0, 0, 0],
            fontSize: 12,
            cellPadding: 0.5,
            minCellHeight: 4,
            font: "times",
            halign: "center",
          },
          showHead: i === 0 || isShowHeader ? "firstPage" : "never",
          headStyles: {
            lineWidth: 0,
            lineColor: [0, 0, 0],
            fontStyle: "bold",
            valign: "middle",
            halign: "center",
            cellPadding: 0.1,
            minCellHeight: 0.1,
            font: "times",
          },
          columnStyles: {
            no: { cellWidth: 15 },
            code: { cellWidth: 20 },
            fund_name: { cellWidth: 30 },
            do_number: { cellWidth: 40 },
            customer_name: { cellWidth: width },
            realization_total: { cellWidth: 20 },
          },
          theme: "plain",
          didParseCell(data) {
            data.cell.styles.fontSize = 11;
            const columnIndex = data.column.index;
            const rowIndex = data.row.index;
            // if (columnIndex === 0) {
            //   data.cell.styles.cellWidth = 2;
            // }
            if (i === body.length - 1) {
              data.cell.styles.fontStyle = "bold";
            }
            if (columnIndex === header.length - 1) {
              data.cell.styles.halign = "right";
            }
          },
          didDrawCell(data) {
            const columnIndex = data.column.index;
            const cellX = data.cell.x;
            const cellY = data.cell.y;
            if (i === 0 || i === body.length - 1 || data.row.section === 'head') {
              doc.setLineWidth(0.01);
              doc.setDrawColor(0, 0, 0);
              doc.line(cellX, cellY, cellX + data.cell.width, cellY);
            }

            // if (data.row.section === 'head') {
            //   // Top line
            //   if (columnIndex === 0) { // Ensure it's drawn only once per row
            //     const topY = cellY;
            //     doc.setLineWidth(0.01);
            //     doc.setDrawColor(0, 0, 0);
            //     doc.line(data.table.startX, topY, data.table.startX + data.table.width, topY);
            //   }

            //   // Bottom line
            //   if (columnIndex === data.table.columns.length - 1) { // Last column ensures the full row width
            //     const bottomY = cellY + data.cell.height;
            //     doc.setLineWidth(0.01);
            //     doc.setDrawColor(0, 0, 0);
            //     doc.line(data.table.startX, bottomY, data.table.startX + data.table.width, bottomY);
            //   }
            // }

            if (columnIndex === 0) {
              // const cellIncm = data.cell.y * toCentiMeter;
              cursorY += data.cell.height;
              colHeight = data.cell.height;
              isShowHeader = false;
            }
          },
        });
      }

      return doc.lastAutoTable.finalY;
    },

    formatPrice(angka, prefix = "") {
      return angka
          .toString()
          .replace(/,/g, "")
          .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },
  },
};
</script>

<style scoped>
.nonfixed {
  position: inherit;
  padding-left: 20px;
}

.core-enter-active {
  transition: all 0.3s ease;
}

.core-leave-active {
  transition: all 0.8s cubic-bezier(1, 0.5, 0.8, 1);
}

.core-enter,
    .core-leave-to
    
    /* .slide-fade-leave-active below version 2.1.8 */ {
  transform: translateX(10px);
  opacity: 0;
}

.core {
  -webkit-transform-origin-y: all 1s ease;
  -webkit-transition: all 1s ease;
  -moz-transition: all 1s ease;
  -o-transition: all 1s ease;
  transition: all 1s ease;
}

.vs-con-table .vs-con-tbody .vs-table--tbody-table {
  font-size: 12px;
}

.vertical-divider {
  border-right: 1px solid #7367f0;
  /* min-height: 800px; */
  /* height: 100%; */
  -webkit-mask-position-y: fixed;
  /* padding: 5px; */
}

.vs-con-table.stripe .tr-values:nth-child(2n) {
  background: beige;
}

.colored {
  border: 1px solid #7367f0;
  position: fixed;
  left: 4%;
  top: 40%;
  max-width: 45%;
  z-index: 999999999999;
  background: antiquewhite;
  background-color: antiquewhite;
}
</style>
